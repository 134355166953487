import React, { Component } from "react";
import Auction from "./Auction";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";

class AuctionList extends Component {
    constructor() {
        super();
        this.state = {
            search: ''
        }
    }

    updateSearch(event) {
        this.setState({search: event.target.value.toLowerCase().substr(0,20)});
    }
    render() {
        const auctions = this.props.auctions;
        const favorites = this.props.favorites;
        //console.log(favorites);
        let filteredAuctions = auctions.filter(
            (auction) =>{
                return auction.title.toUpperCase().indexOf(this.state.search) !== -1 || auction.title.toLowerCase().indexOf(this.state.search) !== -1 || auction.title.indexOf(this.state.search) !== -1 || auction.insurance.indexOf(this.state.search) !== -1 || auction.insurance.toLowerCase().indexOf(this.state.search) !== -1 || auction.insurance.toUpperCase().indexOf(this.state.search) !== -1;
            }
        );
        return (<div>
          <section className="search-car">
    <div className="box">
      <div className="input-field">
        <input type="text" className={"search-custom"} value={this.state.search} onChange={this.updateSearch.bind(this)} placeholder="Wyszukaj"/>
      </div>
    </div>

</section>   
      
                {filteredAuctions.map((auctions) => {
                    return (
                            <Auction favorites={favorites} auctions={auctions} key={auctions.id}/>

                        )
                    })}
        </div>
        );
    }
}

export default AuctionList;