import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";
import Menu from "./Menu";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class BlockUser extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            user: localStorage.getItem('user-email')
        }
    }

    handleInputEmail = (e) => {    this.setState({
        email: e.target.value,
    }); }


    blockUser = async (e) => {
        e.preventDefault();

        const res = await axios.post(apiDomainForComponents+'/api/block', {
            email: this.state.email

        }).then(() => {
            window.alert('Poprawnie usunięto użytkownika');
        })

    }

    render() {
        return <div>
    <Menu/>
            


<section className="login">

<form className="account-form" onSubmit={this.blockUser}>
<div className="icon"><i className="fa-solid fa-user"></i></div>
<h3>Usuń użytkownika</h3>
<div className="input-field">

<input required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInputEmail} className="box"/>
</div>
<button type="submit" className="btn submit" name="login" id="login-btn" style={{color: 'red', background: 'white'}}>Usuń użytkownika</button>
</form> 

</section> 
</div>

    }
}

export default BlockUser;