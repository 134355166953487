import React, { Component } from "react";
import axios from "axios";
import AuctionListForQuests from "./AuctionListForQuests";
import Loader from "./Loader";
import MenuQuests from './MenuQuests';
import "./app.css";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";


class AuctionsForQuests extends Component {
    state = {
        favorites: [],
        auctions: [],
        loader: false,
        url: apiDomainForComponents+"/api/auctions",
    }
    getAuctions = async () => {
        this.setState({loader: true});
      const auctions = await axios.get(this.state.url);
      this.setState({ auctions: auctions.data, loader: false});
    };

    componentDidMount() {
        this.getAuctions();
    }


    render() {

        return <div>
            <MenuQuests/>
                    <AuctionListForQuests favorites={this.state.favorites} auctions={this.state.auctions}/>
                </div>
    }
}

export default AuctionsForQuests;