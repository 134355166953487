import React, { Component } from "react";
import axios from "axios";



class NewAuctionForQuests extends Component {

     render() {
        const {id, thumbnail_link, title, insurance,end_at,created_at,images_content} = this.props.auctions;
        var date1 = new Date(this.props.auctions.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
         return (<div>
            <section className="cars">
          
              <div className="container">
          
                <a href={'/aukcja/'+id}>
                  <div className="car-item"> 
                    <div>
                      <img src={'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} alt=""/>
                    </div>
                    <div className="content"> 
                      <div className="details">
                      <a href={'/zaloguj/'}>
                       <h2><span>{title.slice(0, -14)}</span></h2></a>
          
                       
                        <h3><span>{insurance}</span></h3>
                        <h3><span>{end_at}</span></h3>
                        <h3>{is24 ? (
                             <span className="badge badge-success"> Nowa </span>  ) : (
                                  ''
                                )}</h3>
                      </div> 
                    </div>
                  </div>
                  </a>
                  
              </div>
          
            </section>
                      </div>
        );
    }

}

export default NewAuctionForQuests;