import React, { Component } from "react";
import NewAuctionForQuests from "./NewAuctionForQuests";

class NewAuctionListForQuests extends Component {
    constructor() {
        super();
        this.state = {
            search: ''
        }
    }

    updateSearch(event) {
        this.setState({search: event.target.value.toLowerCase().substr(0,20)});
    }
    render() {
        const auctions = this.props.auctions;
        var date1 = new Date(this.props.auctions.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
        //console.log(favorites);
        let filteredAuctions = auctions.filter(
            (auction) =>{
                return auction.title.toUpperCase().indexOf(this.state.search) !== -1 || auction.title.toLowerCase().indexOf(this.state.search) !== -1 || auction.title.indexOf(this.state.search) !== -1 || auction.insurance.indexOf(this.state.search) !== -1 || auction.insurance.toLowerCase().indexOf(this.state.search) !== -1 || auction.insurance.toUpperCase().indexOf(this.state.search) !== -1;
            }
        );
        return (

            <div>
            <section className="search-car">
      <div className="box">
        <div className="input-field">
          <input type="text" className={"search-custom"} value={this.state.search} onChange={this.updateSearch.bind(this)} placeholder="Wyszukaj"/>
        </div>
      </div>

  </section>   
        
                  {filteredAuctions.map((auctions) => {
                      return (
                              <NewAuctionForQuests auctions={auctions} key={auctions.id}/>

                          )
                      })}
          </div>
        );
    }
}

export default NewAuctionListForQuests;