import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import AuthService from "./../services/AuthService";
import {} from 'react-router-dom';
import "./app.css";
import MenuQuests from './MenuQuests';
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class Login extends Component {
    state = {username: '', password: ''}
    async handleFormSubmit(event) {
        event.preventDefault();
        const postData = {username: this.state.username, password: this.state.password};
        console.log(postData.username);
        localStorage.setItem('user-email', postData.username);
        const response = await AuthService.doUserLogin(postData);
        if (response) {
            AuthService.handleLoginSuccess(response);
            //this.props.history.push('/lista-aukcji');
            window.location.replace("/lista-aukcji");
        }
    }
    render() {
        const {username,password} = this.state;
        return <div>
            <section className="login">

    <form className="account-form" onSubmit={event => this.handleFormSubmit(event)}>
      <div className="icon"><i className="fa-solid fa-user"></i></div>
      <h3>Zaloguj</h3>
      <div className="input-field">
        <label for="email" className="far fa-envelope"></label>
        <input type="text" name="email" placeholder="E-mail" value={username} onChange={event => this.setState({username: event.target.value})} className="box" />
      </div>
      <div className="input-field">
        <label for="password" className="fas fa-lock"></label> 
        <input type="password" name="password" placeholder="Hasło" value={password} onChange={event => this.setState({password: event.target.value})} className="box" />
      </div>
      <button type="submit" className="btn submit" name="login" id="login-btn" style={{color: 'red', background: 'white'}}>Zaloguj</button>
      <p>Aby się zarejestrować napisz na: <a href={'mailto:'+contactEmail}>{contactEmail}</a></p>
      <p><a href={'/przypomnij-haslo'}>Przypomnij hasło</a></p>
    </form> 
  
  </section> 
        </div>
    }
}

export default Login;