import React, { Component } from "react";
import axios from "axios";
import NewAuctionListForQuests from "./NewAuctionListForQuests";
import Loader from "./Loader";
import MenuQuests from './MenuQuests';
import "./app.css";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";
import AuctionListForQuests from "./AuctionListForQuests";


class NewAuctionsForQuests extends Component {
    state = {
        favorites: [],
        auctions: [],
        loader: false,
        url: apiDomainForComponents+"/api/auctions",
    }
    getAuctions = async () => {
        this.setState({loader: true});
      const auctions = await axios.get(this.state.url);
      this.setState({ auctions: auctions.data, loader: false});
    };

    componentDidMount() {
        this.getAuctions();
    }


    render() {

        return <div>
        <MenuQuests/>

<section class="about">

<div class="box-container"> 
  <div class="content">
    <div class="text">
      <p>❗❗ Za pojazdy kupione w ubezpieczalni AXA pobierana jest dodatkowa opłata za wygraną aukcję:<br/>
                    do 999chf bez opłaty,<br/>
                    od 1000 do 4999chf opłata 90chf,<br/>
                    powyżej 5000chf opłata 120chf.<br/>
                    ❗❗ Za pojazdy kupione w ubezpieczalni Allianz pobierana jest dodatkowa opłata za wygraną aukcję:<br/>
                    do 999chf bez opłaty,<br/>
                    od 1000 do 4999chf opłata 70chf,<br/>
                    powyżej 5000chf opłata 110chf.<br/></p>
                {
                    this.state.loader ? <Loader /> : ""
                }
    </div>
  </div>

</div>

</section>
                <AuctionListForQuests favorites={this.state.favorites} auctions={this.state.auctions}/>
            </div>
    }
}

export default NewAuctionsForQuests;