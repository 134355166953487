import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";
import Menu from "./Menu";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class AddUser extends Component {

    constructor() {
        super();
        this.state = {
            password: '',
            passwordConfirm: '',
            email: '',
            name: '',
            user: localStorage.getItem('user-email')
        }
    }


    handleInputName = (e) => {    this.setState({
        name: e.target.value,

    });  }

    handleInputEmail = (e) => {    this.setState({
        email: e.target.value,
    }); }

    handleInputPass = (e) => {    this.setState({
        password: e.target.value,
    }); }

    handleInputPassConfirm = (e) => {    this.setState({
        passwordConfirm: e.target.value,
    }); }

    createUser = async (e) => {
        e.preventDefault();
        if (this.state.password == this.state.passwordConfirm) {
        const res = await axios.post(apiDomainForComponents+'/api/register', {
            password: this.state.password,
            name: this.state.name,
            email: this.state.email

        }).then(() => {
            window.alert('Poprawnie dodano użytkownika');
        })
    }
    else {
        window.alert('Hasła nie są identyczne');
    }

    }

    render() {
        return <div>
        <Menu/>
        
<section className="login">

<form className="account-form" onSubmit={this.createUser}>
<div className="icon"><i className="fa-solid fa-user"></i></div>
<h3>Dodaj użytkownika</h3>
<div className="input-field">

<input required type="text" name="name" placeholder="Nazwa" value={this.state.name} onChange={this.handleInputName} className="box"/>
</div>
<div className="input-field">
<input required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInputEmail} className="box"/>

</div>
<div className="input-field">
<input required type="password" name="password" placeholder="Hasło" value={this.state.password} onChange={this.handleInputPass} className="box"/>

</div>
<div className="input-field">
<input required type="password" name="passwordConfirm" placeholder="Powtórz Hasło" value={this.state.passwordConfirm} onChange={this.handleInputPassConfirm} className="box"/>

</div>
<button type="submit" className="btn submit" name="login" id="login-btn" style={{color: 'red', background: 'white'}}>Dodaj użytkownika</button>
</form> 

</section> 
</div>

    }
}

export default AddUser;