import React, { Component } from "react";
import axios from "axios";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class Auction extends Component {

    addFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/add-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

    dellFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/delete-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

     render() {
        const {id, thumbnail_link, title, insurance,end_at,created_at,images_content} = this.props.auctions;
         const {auction_id} = this.props.favorites;
         const obj = this.props.favorites;
         var date1 = new Date(this.props.auctions.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
         return (
<div>
<section className="cars">

  <div className="container">

    
      <div className="car-item"> 
        <div>
        <a href={'/aukcja/'+id}>
          <img src={'https://api.licytacjezeszwajcarii.pl/storage/'+(images_content.toString().split(','))[0]} alt=""/></a>
        </div>
        <div className="content"> 
          <div className="details">
          <a href={'/aukcja/'+id}>
           <h2><span>{title.slice(0, -14)}</span></h2></a>

           
            <h3><span>{insurance}</span></h3>
            <h3><span>{end_at}</span></h3>
            {(obj.includes(this.props.auctions.id)) ? (
                    <h3 style={{cursor: "pointer"}} onClick={() => this.dellFavorite(id)}><i className="far fa-star" style={{color: "red"}}></i></h3>
                    ) :

                    ( <h3 style={{cursor: "pointer"}} onClick={() => this.addFavorite(id)}><i className="far fa-star" style={{color: "black"}}></i></h3> )
                                }
            <h3>{is24 ? (
                 <span className="badge badge-success"> Nowa </span>  ) : (
                      ''
                    )}</h3>
          </div> 
        </div>
      </div>
      
      
  </div>

</section>
          </div>
        );
    }

}

export default Auction;