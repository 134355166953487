import React, { Component } from "react";
import axios from "axios";
import NewAuctionList from "./NewAuctionList";
import Loader from "./Loader";
import "./app.css";
import Menu from "./Menu";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";


class NewAuctions extends Component {
    state = {
        favorites: [],
        auctions: [],
        loader: false,
        url: apiDomainForComponents+"/api/auctions",
        user: localStorage.getItem('user-email'),
        urlfavorite: apiDomainForComponents+"/api/favorites-stars-list/"+localStorage.getItem('user-email'),

    }
    getAuctions = async () => {
        this.setState({loader: true});
      const auctions = await axios.get(this.state.url);
      this.setState({ auctions: auctions.data, loader: false});
    };


    getFavorites = async () => {
        const favoriteGetted = await axios.get(this.state.urlfavorite);

        this.setState({ favorites: favoriteGetted.data,loader: false});
        console.log(favoriteGetted.data);
    };

    componentDidMount() {
        this.getAuctions();
        this.getFavorites();

    }


    render() {

        return <div>
        <Menu/>

<section class="about">

<div class="box-container"> 
  <div class="content">
    <div class="text">
      <p>❗❗ Za pojazdy kupione w ubezpieczalni AXA pobierana jest dodatkowa opłata za wygraną aukcję:<br/>
                    do 999chf bez opłaty,<br/>
                    od 1000 do 4999chf opłata 90chf,<br/>
                    powyżej 5000chf opłata 120chf.<br/>
                    ❗❗ Za pojazdy kupione w ubezpieczalni Allianz pobierana jest dodatkowa opłata za wygraną aukcję:<br/>
                    do 999chf bez opłaty,<br/>
                    od 1000 do 4999chf opłata 70chf,<br/>
                    powyżej 5000chf opłata 110chf.<br/></p>
                {
                    this.state.loader ? <Loader /> : ""
                }
    </div>
  </div>

</div>

</section>
                <NewAuctionList favorites={this.state.favorites} auctions={this.state.auctions}/>
            </div>
    }
}

export default NewAuctions;