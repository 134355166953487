import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class PasswordReset extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
        }
    }

    handleInput = (e) => {    this.setState({
        email: e.target.value,
    });  }

    SendNewPasswordRequest = async (e) => {
        e.preventDefault();
            const res = await axios.post(apiDomainForComponents+'/api/password/forgot-password', {
                email: this.state.email
            }).then(() => {
                window.alert('Poprawnie wysłano link resetowania');
            })

    }

    render() {
        return <div>
<section className="login">

<form className="account-form" onSubmit={this.SendNewPasswordRequest}>
<div className="icon"><i className="fa-solid fa-user"></i></div>
<h3>Zaloguj</h3>
<div className="input-field">

<input required type="text" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleInput} className="box"/>
</div>

<button type="submit" className="btn submit" name="login" id="login-btn" style={{color: 'red', background: 'white'}}>Wyślij link resetujący</button>
</form> 

</section> 
</div>
    }
}

export default PasswordReset;