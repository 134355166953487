import React, { Component } from "react";
import "./app.css";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";
import axios from "axios";


class Menu extends Component {

    state = {
      auctions: [],
      loader: false,
      url: apiDomainForComponents+"/api/auctions",
      user: localStorage.getItem('user-email'),
  
  }
  
    getAuctions = async () => {
      this.setState({loader: true});
    const auctions = await axios.get(this.state.url);
    this.setState({ auctions: auctions.data, loader: false});
    };
  
    componentDidMount() {
      this.getAuctions();
  }

    render() {
      var arrayOfTrue = [];
        this.state.auctions.forEach((e) => {
          var date1 = new Date(e.created_at);
        var timeStamp = Math.round(new Date().getTime() / 1000);
        var timeStampYesterday = timeStamp - (24 * 3600);
        var is24 = date1 >= new Date(timeStampYesterday*1000).getTime();
        if (is24) { arrayOfTrue.push(true) } 
        })
        const count = arrayOfTrue.length;
        const countAll = this.state.auctions.length;

        return (<div>
          <a href="#" className="scroll-top">
    <i className="fas fa-long-arrow-alt-up"></i>
  </a>
  <header>
    <div className="header">

      <div className="header-2">

        <div id="menu-btn" className="icon fa-solid fa-bars-staggered"></div>
          <div className="logo-name">
            <h2 style={{color: 'red'}}>Art-Cars</h2>
          </div>

        <div className="container">
          <nav className="navbar">

            <a className="nav-btn" href="/lista-aukcji">Lista aukcji<span className="badge badge-secondary"> {countAll} </span></a>

            <a className="nav-btn" href="/lista-nowych-aukcji">Nowe aukcje<span className="badge badge-success"> {count} </span></a>

            <a className="nav-btn" href="/ulubione">Ulubione</a>

            <a className="nav-btn" href="/moje-oferty">Moje oferty</a>

            {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                        
                        <a className="nav-btn" href="/administracja-snfhfhmngfdsbhjjhgfgfffdddxcvbbb">Administracja</a>
                       ) : (null)
                    }
                    {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                        
                        <a className="nav-btn" href="/dodaj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb">Dodaj użytkownika</a>
                       ) : (null)
                    }
                      {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                        
                        <a className="nav-btn" href="/usun-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb">Usuń użytkownika</a>
                       ) : (null)
                    }
                      {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                      
                        <a className="nav-btn" href="/uzytkownicy">Lista użytkowników</a>
                       ) : (null)
                    }

            <a className="nav-btn" href="/">Wyloguj</a>

          </nav> 

        </div>

      </div>

    </div>
    <div className="mobile-menu"> 

      <div id="close-side-bar" className="fa-solid fa-angles-left"></div> 
    
      <nav className="mobile-navbar">

        <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn" href="/">Lista aukcji<span className="badge badge-secondary"> {countAll} </span></a> </div>
        </div>   

        <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn" href="/nowe-aukcje">Nowe aukcje<span className="badge badge-success"> {count} </span></a> </div>
        </div>  

        <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn" href="/ulubione">Ulubione</a> </div>
        </div>

        <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn" href="/moje-oferty">Moje oferty</a> </div>
        </div>


        {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                        <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn" href="/administracja-snfhfhmngfdsbhjjhgfgfffdddxcvbbb">Administracja</a></div>
        </div>
                       ) : (null)
                    }
                    {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                        
                        <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn"  href="/dodaj-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb">Dodaj użytkownika</a></div>
          </div>
                       ) : (null)
                    }
                      {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                        
                        <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn"  href="/usun-uzytkownika-snfhfhmngfdsbhjjhgfgfffdddxcvbbb">Usuń użytkownika</a></div>
          </div>
                       ) : (null)
                    }
                      {((this.state.user == "hafenteile@gmail.com") || (this.state.user == secondAdminEmail)) ? (
                      
                      <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn"  href="/uzytkownicy">Lista użytkowników</a></div>
          </div>
                       ) : (null)
                    }
        <div className="nav-link">
          <div className="main-nav-link"> <a className="nav-btn" href="/">Wyloguj</a> </div>
        </div>  

      </nav>
    
    </div>
  </header> 
  <section className="home">

    <div className="swiper-container home-slider">
      <div className="swiper-wrapper">

        <div className="swiper-slide home-item">
          <img src="/ai-generated-8649919_1920.png" alt=""/>
        </div>

      </div>

    </div>

  </section>
  </div> )
    }
}

export default Menu;