import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {} from 'react-router-dom';
import "./app.css";
import axios from "axios";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class PasswordChange extends Component {

    constructor() {
        super();
        this.state = {
            password: '',
            confirmedpassword: '',
        }
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        localStorage.setItem("token", urlParams.get('token'));
        localStorage.setItem("email", urlParams.get('email'));
    }


    handleInputPass = (e) => {    this.setState({
        password: e.target.value,

    });console.log(this.state.token);  }

    handleInputCPass = (e) => {    this.setState({
        confirmedpassword: e.target.value,
    }); }

        changePassword = async (e) => {
        e.preventDefault();

        const res = await axios.post(apiDomainForComponents+'/api/password/reset', {
            password: this.state.password,
            password_confirmation: this.state.confirmedpassword,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token")

        }).then(() => {
            window.alert('Poprawnie zmieniono hasło');
        })

    }

    render() {
        return  <div>
            <section className="login">

    <form className="account-form" onSubmit={this.changePassword}>
      <div className="icon"><i className="fa-solid fa-user"></i></div>
      <h3>Zaloguj</h3>
      <div className="input-field">

      <input required type="password" name="password" placeholder="Hasło" value={this.state.password} onChange={this.handleInputPass} className="box"/>
      </div>
      <div className="input-field">
      <input required type="password" name="confirmedpassword" placeholder="Powtórz hasło" value={this.state.confirmedpassword} onChange={this.handleInputCPass} className="box"/>
      
      </div>
      <button type="submit" className="btn submit" name="login" id="login-btn" style={{color: 'red', background: 'white'}}>Zmień hasło</button>
    </form> 
  
  </section> 
        </div>
    }
}

export default PasswordChange;