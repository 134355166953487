import React, { Component } from "react";
import axios from "axios";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class AuctionDetails extends Component {

    constructor() {
        super();
        this.state = {
            price: '',
            user: localStorage.getItem('user-email'),
            favorites: [],
            auctions: []
        }
    }

    addFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/add-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

    dellFavorite = async (id) => {
        const res = await axios.get(apiDomainForComponents+'/api/delete-favorite/'+localStorage.getItem('user-email')+'/'+id);
        window.location.reload(false);

    }

    getFavorites = async () => {
        const favoriteGetted = await axios.get(this.state.urlfavorite);

        this.setState({ favorites: favoriteGetted.data,loader: false});
        console.log(favoriteGetted.data);
    };

    getAuctions = async () => {
        this.setState({loader: true});
      const auctions = await axios.get(this.state.url);
      this.setState({ auctions: auctions.data, loader: false});
    };


    handleInput = (e) => {    this.setState({
        price: e.target.value,
    });  }

    saveOffer = async (e) => {
        e.preventDefault();
        let agree = window.confirm("Potwierdź złożenie oferty w wysokości "+ this.state.price + " CHF?");
        if (agree) {
            const res = await axios.post(apiDomainForComponents+'/api/add-offer', {
                price: this.state.price,
                auctionid: this.props.auction.id,
                useremail: localStorage.getItem('user-email'),
                title: this.props.titleSliced,
                insurance: this.props.auction.insurance,
            }).then(() => {
                window.alert('Poprawnie zalicytowano');
                window.location.reload(false);
            })
        }
        else {
            return false;
        }
        }



    render() {
        const {id, title, insurance,end_at, description_table} = this.props.auction;
        const imgArray = this.props.images.toString().split(',');
        const titleSliced = this.props.titleSliced;
        const auctionsadmins = this.props.auctionsadmins;
        const obj = this.state.favorites;
        return (
            <div>
                <div className="car-description" style={{padding: '15px'}}>

<div className="box-container">

  <div className="image">
    <div className="image-container">
      <div>
      {imgArray.map((image) => {
                    return (
                        <img src={'https://api.licytacjezeszwajcarii.pl/storage/'+image} alt={image} key={image}/>
                    )
                })}
      </div>
    </div>
  </div>
  
  <div className="content">
    <div className="text">
      
      <div className="car-intro">
      {(obj.includes(this.props.auction.id)) ? (
                    <h2 style={{cursor: "pointer"}} onClick={() => this.dellFavorite(id)}><i className="far fa-star" style={{color: "red"}}></i></h2>
                    ) :

                    ( <h2 style={{cursor: "pointer"}} onClick={() => this.addFavorite(id)}><i className="far fa-star" style={{color: "black"}}></i></h2> )
                                }
        <h2>{titleSliced} {insurance} <br/> Data zakończenia: {end_at}</h2>
      </div> 

    </div>
  </div>
</div>

<div className="car-info tab-info" dangerouslySetInnerHTML={{__html: description_table}}>
    </div>
                {(document.referrer == (window.location.protocol + '//' + window.location.host + '/moje-oferty')) ? (<div></div>
                 ) : (
                    
                
                <section className="reset-pwd"> 

                <form className="account-form" onSubmit={this.saveOffer}>
                  <h3>Złóż ofertę</h3>
                  <div className="input-field">
                    <input type="number" name="price" value={this.state.price} onChange={this.handleInput}
                                   placeholder="Cena w CHF" className="box"/>
                            <input type="hidden" name="auctionid" value={id} className="box"/>
                            <input type="hidden" name="insurance" value={insurance} className="box"/>
                            <input type="hidden" name="title" value={titleSliced} className="box"/>
                            <input type="hidden" name="useremail" value={localStorage.getItem('user-email')} className="box"/>
                  </div>
                  <button type="submit" className="btn" style={{color: 'red', background: 'white'}}>Zalicytuj</button>
                </form>
              
              </section>)
              
                }
                               {((this.state.user === "hafenteile@gmail.com") || (this.state.user === secondAdminEmail)) ? (
                    <div className="data">
                <table className="ui celled table">
                    <thead>
                    <tr>
                        <th style={{width: "50px"}}>Data</th>
                        <th>Tytuł</th>
                        <th>Użytkownik</th>
                        <th>Cena</th>
                    </tr>
                    </thead>

                    <tbody>

                    {auctionsadmins.map((auctionsadmins) => {
                        return (
                            <tr>

                                <td>{auctionsadmins.created_at.split(".")[0].replace("T"," ")}</td>
                                <td>{auctionsadmins.auction_title}</td>
                                <td>{auctionsadmins.user_email}</td>
                                <td>{auctionsadmins.price} CHF</td>
                            </tr>
                        )
                    })}

                    </tbody>
                </table>
            </div>) : (null)
                    }
            </div>
            </div>
        );
    }

}

export default AuctionDetails;