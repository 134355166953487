import React, { Component } from "react";
import axios from "axios";
import Menu from "./Menu";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";

class Users extends Component {
    
    
    state = {
        users: [],
        url: apiDomainForComponents+"/api/users",
        search: '',
        user: localStorage.getItem('user-email')
      }


    updateSearch(event) {
        this.setState({search: event.target.value.toLowerCase().substr(0,20)});
    }
     getUsers = async () => {
      const users = await axios.get(this.state.url);
      this.setState({ users: users.data});
    };
     componentDidMount() {
        this.getUsers();
    }


    render() {
      
      let filteredUsers = this.state.users.filter(
            (usersS) =>{
                return usersS.email.toUpperCase().indexOf(this.state.search) !== -1;
            }
        );
        
       return ( 
        
        <div>
   <Menu/>
                 <div className="data p-5">
                    <div className="ui input" style={{ minWidth: "60vw",display: "none"}}>
                        <input type="text" value={this.state.search} onChange={this.updateSearch.bind(this)} placeholder="Wyszukaj"/>
                    </div>
                    <div>
                        </div>
                
                        {filteredUsers.map((users) => {
                      return (
                        <div><div>
                              
            <div className="ui segment">
            
        
          
                              <h2> {users.email} </h2>
                             </div>
                             </div>
                             </div>
                    )
                })}
                </div>
                
               
          </div>
            
            );
    }
}


export default Users;
